import React from 'react'
import Link from 'gatsby-link'
import Layout from '../layouts/index'

const NotFoundPage = ({location}) => (
    <Layout location={location}>
        <div>
            <div style={{maxWidth: 1170, padding: '20px', margin: 'auto'}}>
                <h1 style={{fontSize: '4rem'}}>Oops!</h1>
                <p>Nous n'arrivons pas à retrouver la page que vous cherchez.</p>
                <p>Code de l'erreur: 404</p>
                <p style={{marginBottom: 4}}>Essayer les liens ci-dessous à la place:</p>
                <ul>
                    <li style={{margin: 0}}><Link to="/">Accueil</Link></li>
                    <li style={{margin: 0}}><Link to="/reservation">Résever un consultation</Link></li>
                    <li style={{margin: 0}}><Link to="/prestations">Nos prestations</Link></li>
                    <li style={{margin: 0}}><Link to="/contact">Nous rejoindre</Link></li>
                </ul>
            </div>


        </div>
    </Layout>
)

export default NotFoundPage
